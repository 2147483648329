<template>
  <div class="financial-revenues">
    <b-table
      :data="allFilterCustomers"
      ref="table"
      paginated
      per-page="10"
      :striped="true"
      :debounce-search="1000"
      aria-next-label="Next page"
      aria-previous-label="Previous page"
      aria-page-label="Page"
      aria-current-label="Current page"
    >
      <b-table-column
        field="type"
        label="Nome"
        :searchable="true"
        sortable
        v-slot="props"
        >{{ props.row.name.toUpperCase() }}</b-table-column
      >

      <b-table-column
        field="status"
        label="Status"
        :searchable="true"
        sortable
        v-slot="props"
      >
        <b-taglist style="margin-top: 3px; font-weight: bold">
          <b-tag
            :type="
              props.row.status === 'FATURADO'
                ? 'is-success'
                : props.row.status === 'ATRASADO'
                ? 'is-danger'
                : props.row.status === 'PENDENTE'
                ? 'is-warning'
                : 'is-light'
            "
            >{{ props.row.status }}</b-tag
          >
        </b-taglist>
      </b-table-column>

      <b-table-column v-slot="props" label="Opções">
        <button
          class="button is-small is-success"
          @click.prevent="
            onEdit(props.row)
            isRevenueModalActive = true
          "
        >
          <b-icon icon="check" size="is-small"></b-icon>
        </button>
      </b-table-column>
    </b-table>

    <b-modal v-model="isRevenueModalActive" :width="640" scroll="keep">
      <div class="card" style="height: auto">
        <div class="card-content">
          <div class="content">
            <b-field>
              <b-collapse
                class="card"
                animation="slide"
                :open="isOpen == index"
                @open="isOpen = index"
              >
                <div
                  slot="trigger"
                  slot-scope="props"
                  class="card-header"
                  role="button"
                >
                  <p class="card-header-title">Faturamentos</p>
                  <a class="card-header-icon">
                    <b-icon :icon="props.open ? 'menu-down' : 'menu-up'">
                    </b-icon>
                  </a>
                </div>
                <div class="card-content">
                  <div class="content">
                    <b-field
                      :label="item"
                      v-for="(item, index) of months"
                      :key="index"
                    >
                      <b-tag
                        :type="
                          verifyRevenue(model.revenues, index)
                            ? 'is-success'
                            : 'is-danger'
                        "
                        >{{
                          verifyRevenue(model.revenues, index)
                            ? 'FATURADO'
                            : 'NÃO FATURADO'
                        }}</b-tag
                      >
                    </b-field>
                  </div>
                </div>
              </b-collapse>
            </b-field>

            <b-field label="Selecione a data">
              <b-datepicker
                placeholder="Clique para selecionar"
                v-model="model.date"
                inline
              >
              </b-datepicker>
            </b-field>
            <b-field>
              <button @click="save" class="button field is-info is-light">
                <span>Salvar</span>
              </button>
            </b-field>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex'
import service from '../features/financial-revenues/store/service'
import FinancialRevenue from '../models/financial-revenue'

export default {
  name: 'FinancialRevenues',
  data() {
    return {
      model: FinancialRevenue.model,
      showDetailIcon: false,
      isCardModalActive: false,
      isRevenueModalActive: false,
      allFilterFinancialRecurrence: [],
      allFilterFinancialSubCategories: [],
      financialCategoryId: null,
      allFilterCustomers: [],
      moneyFormat: {
        prefix: 'R$ ',
        sstatefix: '',
        precision: 2,
        masked: false,
      },
      months: [
        'Janeiro',
        'Fevereiro',
        'Março',
        'Abril',
        'Maio',
        'Junho',
        'Julho',
        'Agosto',
        'Setembro',
        'Outubro',
        'Novembro',
        'Dezembro',
      ],
    }
  },
  created() {
    this.init()
  },
  computed: {
    ...mapState('customers', ['allCustomers']),
  },
  methods: {
    ...mapActions('customers', ['getAllCustomers']),
    verifyRevenue(revenues, index) {
      if (!revenues || !(revenues instanceof Array)) return false

      for (const item of revenues) {
        const revenueMonth = new Date(item.date).getMonth()

        if (revenueMonth === index) {
          return true
        }
      }

      return false
    },
    async init() {
      await this.getAllCustomers()

      const currentMonth = new Date().getMonth()

      this.allFilterCustomers = []

      for (const customer of this.allCustomers) {
        if (customer.revenues.length) {
          for (const revenue of customer.revenues) {
            console.log(revenue)
            const revenueDate = new Date(revenue.date)
            if (currentMonth === revenueDate.getMonth()) {
              customer.status = 'FATURADO'
              console.log('faturado')
            }

            if (!customer.status && customer.total_services > 0) {
              revenueDate.setDate(customer.revenue_day)
              const time = revenueDate.valueOf() - Date.now()
              const days = time / (1000 * 3600 * 24)

              if (days < 0) {
                customer.status = 'ATRASADO'
              } else {
                customer.status = 'PENDENTE'
              }
            } else {
              customer.status = 'SEM SERVIÇO'
            }
          }
        } else {
          if (customer.total_services > 0) {
            const revenueDate = new Date()
            revenueDate.setDate(customer.revenue_day)
            const time = revenueDate.valueOf() - Date.now()
            const days = time / (1000 * 3600 * 24)

            if (days < 0) {
              customer.status = 'ATRASADO'
            } else {
              customer.status = 'PENDENTE'
            }
          } else {
            customer.status = 'SEM SERVIÇO'
          }
        }

        this.allFilterCustomers.push(customer)
      }
    },
    onEdit(item) {
      this.model = { ...item }
    },
    async save() {
      const revenue = {}

      revenue.date = this.$moment(this.model.date).format('YYYY-MM-DD')
      revenue.customer_id = this.model.id

      console.log(revenue)

      try {
        await service.saveFinancialRevenues(revenue)
        await this.$success('Faturamento')
        location.reload(true)
      } catch (error) {
        console.log(error)
        this.$error(error.response.data.message)
      }
    },
    async onDelete(id) {
      try {
        const result = await this.$ask('Você deseja remover o faturamento?')

        if (result.isConfirmed) {
          // await service.deleteFinancialRecurrence(id)

          await this.$delete('Faturamento')
          location.reload(true)
        }
      } catch (error) {
        this.$error(error.response.data.message)
      }
    },
  },
}
</script>

<style scoped></style>